import { supabaseAuthProvider } from 'ra-supabase';
import { supabaseClient } from './components/supabase';

const customAuthProvider = supabaseAuthProvider(supabaseClient, {
  getIdentity: async (user) => {
    console.log(`Running customAuthProvider.getIdentity()`);
    if (localStorage.getItem('identity'))
      return JSON.parse(localStorage.getItem('identity'));

    const { data: teachersData, error: teachersError } = await supabaseClient
      .from('teachers')
      .select('id, first_name, last_name, role, class_id, grade')
      .match({ email: user.email })
      .single();

    const { data: parentsData, error: parentsError } = await supabaseClient
      .from('parents')
      .select('id, first_name, last_name, role, household_hash')
      .match({ email: user.email })
      .single();

    const error = teachersError && parentsError; // && studentsError;
    const data = teachersData || parentsData; // || studentsData;

    if (error) throw new Error('Authprovider: error: ' + error);
    if (!data) throw new Error('AuthProvider: No data!');

    const identity = {
      id: data.id,
      fullName: `${data.first_name} ${data.last_name}`,
      role: `${data.role}`,
      household_hash: `${data.household_hash}`,
      class_id: data.class_id || null,
      grade: data.grade || null,
    };

    console.log(`Running localStorage.setItem('identity', {})`);
    localStorage.setItem('identity', JSON.stringify(identity));
    return identity;
  },
  getPermissions: () => {
    console.log(`Running customAuthProvider.getPermissions()`);
    const identity = localStorage.getItem('identity');
    console.log(
      `customAuthProvider.getPermissions -> identity: ${JSON.stringify(
        identity
      )}`
    );
    return identity
      ? Promise.resolve(JSON.parse(identity).role)
      : Promise.reject();
  },
});

export const authProvider = {
  ...customAuthProvider,
  // checkAuth: () => localStorage.getItem('identity') ? Promise.resolve() : Promise.reject(),
  checkError: (error) => {
    console.log(`Running authProvider.checkError()`);
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('identity');
      localStorage.removeItem('lessonsArchived');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  login: async (emailPasswordParams) => {
    console.log(
      `Running authProvider.login() with ${JSON.stringify(emailPasswordParams)}`
    );
    const { error } = await supabaseClient.auth.signInWithPassword(
      emailPasswordParams
    );
    console.log('authProvider.login() -> error data: ' + JSON.stringify(error));
    if (error) {
      throw new Error(error.message);
    } else {
      const identity = await customAuthProvider.getIdentity(
        emailPasswordParams
      );
      console.log(
        `The identity retrieved by authProvider.login() -> ${JSON.stringify(
          identity
        )}`
      );
      return Promise.resolve();
    }
  },
  logout: async () => {
    console.log('Custom logout method called');
    const { error } = await supabaseClient.auth.signOut();
    if (error) throw new Error(error.message);
    console.log('Logged out');
    localStorage.removeItem('identity');
    localStorage.removeItem('lessonsArchived');
    return Promise.resolve();
  },
};
