import React, { useState, useEffect } from 'react'; //  useEffect
import {
  Admin,
  Resource,
  AppBar,
  Layout,
  TitlePortal,
  // ToggleThemeButton,
  defaultTheme,
  CustomRoutes,
  // useGetIdentity,
  // EditGuesser
} from 'react-admin';
import { supabaseClient } from './components/supabase';
import { BrowserRouter, Route } from 'react-router-dom';
import { Box, createTheme } from '@mui/material';
// import Carousel from './components/Carousel';
import { yellow, blue } from '@mui/material/colors';

import ClassCreate from './components/ClassCreate';
import LessonCreate from './components/LessonCreate';
import ParentCreate from './components/ParentCreate';
import StudentCreate from './components/StudentCreate';
import SubjectCreate from './components/SubjectCreate';
import TeacherCreate from './components/TeacherCreate';

import StudentList from './components/StudentList';
import ClassList from './components/ClassList';
import TermList from './components/TermList';
import SubjectList from './components/SubjectList';
import TeacherList from './components/TeacherList';
import ParentList from './components/ParentList';
import LessonList from './components/LessonList';
import LessonItemList from './components/LessonItemList';
import LessonShow from './components/LessonShow';

import StudentEdit from './components/StudentEdit';
import SubjectEdit from './components/SubjectEdit';
import ClassEdit from './components/ClassEdit';
import ParentEdit from './components/ParentEdit';
import TeacherEdit from './components/TeacherEdit';
import LessonEdit from './components/LessonEdit';
import LessonItemEdit from './components/LessonItemEdit';
import LessonItemCreate from './components/LessonItemCreate';
import ResourceList from './components/ResourceList';
import ResourceEdit from './components/ResourceEdit';
import ResourceShow from './components/ResourceShow';
import ResourceCreate from './components/ResourceCreate';
import TermCreate from './components/TermCreate';
import TermEdit from './components/TermEdit';

// import ClassShow from './components/ClassShow';
// import StudentShow from './components/StudentShow';
// import TeacherShow from './components/TeacherShow';

import Dashboard from './components/Dashboard';
// import UserIcon from '@mui/icons-material/People'; // For students
import Groups2Icon from '@mui/icons-material/Groups2'; // For students
// import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'; // For teachers
import LightbulbIcon from '@mui/icons-material/Lightbulb'; // For teachers
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import SchoolIcon from '@mui/icons-material/School';
// import ClassIcon from '@mui/icons-material/Class'; // Was used for class
// import ListIcon from '@mui/icons-material/List';
import ViewListIcon from '@mui/icons-material/ViewList';
import CategoryIcon from '@mui/icons-material/LocalOffer';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import { authProvider } from './AuthProvider';
import { dataProvider } from './DataProvider';

// import { MyMildredPage } from './components/MyMildredPage';
import { MyLoginPage } from './components/MyLoginPage';
import { MySetPasswordPage } from './components/MySetPasswordPage';
import { MyForgotPasswordPage } from './components/MyForgotPasswordPage';
import { MyVerifyAccountPage } from './components/MyVerifyAccountPage';

// import { LoginPage, SetPasswordPage, ForgotPasswordPage, supabaseDataProvider } from 'ra-supabase';

const lightTheme = createTheme({
  ...defaultTheme,
  palette: {
    mode: 'light',
    primary: {
      // main: '#003bfc',
      main: blue[900], // #0D47A1
    },
    secondary: {
      // main: '#FCC100',
      main: yellow[700], // #FBC02D
    },
    background: {
      // default: '#fafafa',
      paper: '#f5f5f5',
    },
  },
  // components: {
  //   ...defaultTheme.components,
  //   MyCarousel: {
  //     top: '-50px',
  //     border: '1px solid red'
  //   }
  // }
});

// const darkTheme = createTheme({
//   ...defaultTheme,
//   palette: {
//     mode: 'dark',
//     primary: {
//       main: yellow[900], // #FBC02D
//     },
//   },
// });

const MyAppBar = () => (
  <AppBar color="secondary" alwaysOn>
    <TitlePortal />
    <Box
      component="span"
      bgcolor="white"
      px={8}
      border={1}
      borderRadius={4}
      sx={{ fontSize: '1.5em' }}
    >
      Die Loerieweb
    </Box>
    <Box component="span" flex={1} />
    {/* <ToggleThemeButton lightTheme={lightTheme} darkTheme={darkTheme} /> */}
  </AppBar>
);
// const MyAppBar = () => (<AppBar color="secondary" alwaysOn />);
const MyLayout = (props) => <Layout {...props} appBar={MyAppBar} />;

const MaintenancePage = () => (
  <div style={{ textAlign: 'center', marginTop: '20%' }}>
    <h1>We’re currently undergoing maintenance</h1>
    <p>Please check back later. Thank you for your patience!</p>
  </div>
);

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [isMaintenance, setIsMaintenance] = useState(false);

  useEffect(() => {
    const checkMaintenanceMode = async () => {
      try {
        const { data, error } = await supabaseClient
          .from('system')
          .select('value')
          .eq('key', 'maintenance')
          .single();

        if (error) {
          console.error('Error fetching maintenance state:', error);
        } else {
          setIsMaintenance(data.value);
        }
      } catch (error) {
        console.error('Unexpected error:', error);
      } finally {
        setIsLoading(false);
      }
    };
    checkMaintenanceMode();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isMaintenance) {
    return <MaintenancePage />;
  }

  //useEffect(() => {
  //  window.process = {
  //    ...window.process,
  //  };
  //}, []);
  // const { data: identityData, isLoading: identityLoading, error: identityError } = useGetIdentity();
  // if (identityLoading) return <>Loading....</>;
  // if (identityError) return <>Error...</>;
  return (
    <BrowserRouter>
      <Admin
        loginPage={MyLoginPage}
        theme={lightTheme}
        dashboard={Dashboard}
        dataProvider={dataProvider}
        authProvider={authProvider}
        layout={MyLayout}
        requireAuth
        title="Loerieweb"
      >
        <CustomRoutes noLayout>
          <Route
            path={MySetPasswordPage.path}
            element={<MySetPasswordPage />}
          />
          <Route
            path={MyForgotPasswordPage.path}
            element={<MyForgotPasswordPage />}
          />
          <Route
            path={MyVerifyAccountPage.path}
            element={<MyVerifyAccountPage />}
          />
        </CustomRoutes>
        {(permissions, identityData) => (
          <>
            {console.log('App -> permissions: ' + permissions)} &&
            {['admin'].includes(permissions) ? (
              <>
                <Resource
                  icon={FamilyRestroomIcon}
                  name="parents"
                  list={ParentList}
                  create={permissions === 'admin' ? ParentCreate : null}
                  edit={ParentEdit}
                  recordRepresentation={(record) =>
                    `${record.first_name} ${record.last_name}`
                  }
                />
                <Resource
                  icon={SchoolIcon}
                  name="teachers"
                  list={TeacherList}
                  create={permissions === 'admin' ? TeacherCreate : null}
                  edit={TeacherEdit}
                  recordRepresentation={(record) =>
                    `${record.first_name} ${record.last_name}`
                  }
                />
              </>
            ) : null}
            {['admin', 'parent', 'teacher'].includes(permissions) ? (
              <Resource
                icon={Groups2Icon}
                name="students"
                options={
                  permissions === 'parent'
                    ? { label: 'Children' }
                    : { label: 'Students' }
                }
                list={<StudentList permissions={permissions} />}
                create={permissions === 'admin' ? StudentCreate : null}
                edit={StudentEdit}
                recordRepresentation={(record) =>
                  `${record.first_name} ${record.last_name}`
                }
              />
            ) : null}
            {['admin'].includes(permissions) ? (
              <>
                <Resource
                  icon={CalendarMonthIcon}
                  name="terms"
                  list={TermList}
                  create={TermCreate}
                  edit={TermEdit}
                  recordRepresentation={(record) => `${record.name}`}
                />
                <Resource
                  icon={CategoryIcon}
                  name="subjects"
                  list={SubjectList}
                  create={permissions === 'admin' ? SubjectCreate : null}
                  edit={SubjectEdit}
                  recordRepresentation={(record) => `${record.subject}`}
                />
                <Resource
                  icon={LightbulbIcon}
                  name="classes"
                  list={ClassList}
                  create={permissions === 'admin' ? ClassCreate : null}
                  edit={ClassEdit}
                  recordRepresentation={(record) => `${record.class_code}`}
                />
              </>
            ) : null}
            {['admin', 'teacher', 'parent', 'student'].includes(permissions) ? (
              <>
                <Resource
                  icon={ViewListIcon}
                  name="lessons"
                  list={LessonList}
                  create={
                    ['admin', 'teacher'].includes(permissions)
                      ? LessonCreate
                      : null
                  }
                  edit={LessonEdit}
                  show={LessonShow}
                >
                  <Route path=":id/lessonItems" element={<LessonItemList />} />
                  <Route
                    path=":id/lessonItems/:lessonItemId"
                    element={<LessonItemEdit />}
                  />
                  <Route
                    path=":id/lessonItems/create"
                    element={<LessonItemCreate />}
                  />
                </Resource>
                <Resource
                  icon={SchoolIcon}
                  name="resources"
                  list={ResourceList}
                  create={
                    ['admin', 'teacher'].includes(permissions)
                      ? ResourceCreate
                      : null
                  }
                  edit={ResourceEdit}
                  show={ResourceShow}
                />
                <Resource
                  name="resource_types"
                  recordRepresentation={(record) => `${record.name}`}
                />
              </>
            ) : null}
            {/* ["admin","teacher","parent","student"].includes(permissions) ?
              (<>
              <Resource icon={ViewListIcon} name="resources" list={ResourceList} edit={ResourceEdit}>
                <Route path=":id/lessonItems" element={<ResourceItemList />} />
                <Route path=":id/lessonItems/:lessonItemId" element={<ResourceItemEdit />} />
              </Resource>
              </>) : null */}
          </>
        )}
      </Admin>
      {/* <Carousel>Toets</Carousel> */}
    </BrowserRouter>
  );
}

export default App;
